/* !========================================== */
/* about container section  */
.tokenomicsBase{
    background-color: var(--bg);
    width: 100%;
    height: 100%;
}
.tokenomicsBase .title{
    text-align: center;
    font-size: 50px;
    color: var(--font);
    padding-top: 50px;
    padding-bottom: 65px;
}

/* !========================================== */
/* first 2 items sction */
.tokenomicsBase .info{
    display: flex;
    flex-direction: column;
}
.tokenomicsBase .info .title{
    font-size: 40px;
    padding-top: 40px;
    padding-bottom: 20px;

}
.tokenomicsContainer{
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2,50%);
    place-items: center;
}
.tokenomicsContainer img, .tokenomicsContainer2 img{
    width: auto;
    height: 60px;
    pointer-events:none

}
.tokenomicsContainer .left .info, .tokenomicsContainer .right .info{
    border: 2px solid var(--font2);
    border-radius:var(--border);
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
   
}
.tokenomicsContainer .icon{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

/* !========================================== */
/* second  row 3 items section */
.tokenomicsContainer2 {
    padding-top: 50px;
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,33%);
    place-items: center;
}
.tokenomicsContainer2 .icon{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
} 
.tokenomicsContainer2 .info{
    border: 2px solid var(--font2);
    border-radius:var(--border);
    width: 420px;
    height: 280px;
    display: flex;
    align-items: center;
   
}
.tokenomicsContainer2 .text,.tokenomicsContainer .text{
    font-size: 22px;
    text-align: justify;
    color: var(--font2);
    width: 90%;
}

/* !========================================== */
/* mobile version */
@media all and (max-width: 1750px){
    .tokenomicsContainer .left .info, .tokenomicsContainer .right .info{
        width: 500px;
    }
}

@media all and (max-width: 1630px){
    .tokenomicsContainer2 .info{
        width: 370px;       
    }
}

@media all and (max-width: 1450px){
    .tokenomicsContainer .left .info, .tokenomicsContainer .right .info{
        width: 400px;
    }
    .tokenomicsContainer2 .info{
        width: 300px;       
    }
}

@media all and (max-width: 1160px){
    .tokenomicsContainer .left .info, .tokenomicsContainer .right .info{
        width: 34vw;
    }
    .tokenomicsContainer .left .info .text, .tokenomicsContainer .right .info .text{
        font-size: 2.1vw;
    }
    .tokenomicsContainer2 .info{
        width: 25vw;       
    }
}

@media all and (max-width: 800px){
    .tokenomicsContainer{
        width: 80%;
        grid-template-columns: 100%;
    }

    .tokenomicsContainer .left .info, .tokenomicsContainer .right .info{
        width: 95%;
        height: 400px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .tokenomicsContainer .left .info .text, .tokenomicsContainer .right .info .text{
        font-size: 22px;
    }

    .tokenomicsContainer2 {
        padding-top: unset;
        grid-template-columns: 100%;
    }
    .tokenomicsContainer2 .info{
        width: 95%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .tokenomicsContainer2 .icon{
        margin-bottom: 10px;
    } 
}

@media all and (max-width: 500px){

    .tokenomicsContainer .left .info .text{
        font-size: 21px;
    } 
}

@media all and (max-width: 400px){
    .tokenomicsBase .info .title{
        font-size: 30px;
    }
    .tokenomicsContainer .left .info .text{
        font-size: 18px;
    } 
}