.rarityBase{
    width: 100%;
    height: 100%;
    background: var(--bg);
}
.rarityBase .title{
    padding: 120px 0px;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: var(--font);
}

.rarityContainer{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rarityContainer svg{
    width: 100vw;
    height: auto;
}
/* animation */
/* 
.animation {
    animation: scaleUp 3s linear 0.01s forwards;
    transform-origin: bottom;
    transform-box: fill-box;
    -webkit-transform: scaleY(0%);

    -webkit-animation-name: scaleUp;
    -webkit-animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;

}

@keyframes scaleUp {
    0%{
        -webkit-transform: scaleY(0%);
    }
    100%{
        -webkit-transform: scaleY(100%);
    }
}
@-webkit-keyframes scaleUp{
    0%{
        -webkit-transform: scaleY(0%);
    }
    100%{
        -webkit-transform: scaleY(100%);
    }
} */

/* mobile version */

@media all and (max-width: 800px){
    .rarityBase .title{
        padding: 65px 0px;
    }
}