
.footerBase{
    height: 60px;
    background: #040404;
    width: 100%;
}
.footerBase a{
    text-decoration: none;
    animation: opacity 3s ease infinite;
    font-size: 1.3vw;
    color: var(--font);
}
.socials{
    display: flex;
    width: 40%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
}

.socials .twitter a:hover{
    color: #1DA1F2;
}
.socials .discord a:hover{
    color: #7289da;
}
.socials .contract a:hover{
    color: #eb5b5c;
}
@keyframes opacity{
    0%,100%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
}

@media all and (max-width: 800px){
    .socials a{
        font-size: 2.3vw;
    }
}
@media all and (max-width: 500px){
    .socials a{
        font-size: 13px;
    }
    .socials{
        width: 65%;
    }
}