.roadmapBase{
    width: 100%;
    height: 100%;
    background: var(--bg);
}
.roadmapBase .title{
    padding: 120px 0px;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: var(--font);
}

.roadmapContainer{
    width: 85vw;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.roadmapContainer img{
    width: 90vw;
    height: auto;
}
.roadmapContainer img{
    pointer-events:none
}

@media all and (max-width: 800px){
    .rarityBase .title{
        padding: 65px 0px;
    }
}