.showRoomBase{
    background: var(--bg);
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.showRoomContainer{
    width: 80%;
    margin: 0 auto;
}
/* !=========================================================== */
/* !top reward section */
.showRoomContainer .rewardSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.showRoomContainer .rewardSection .rewardAmount{
    display: flex;
    justify-content: center;
}

.showRoomContainer .rewardText{
    font-size: 30px;
    color: var(--font);
    margin-right: 10px;
}
.showRoomContainer .rewardSection button{
    width: 350px;
    height: 30px;
    border-radius: var(--border);
    background: var(--accent1);
    border: unset;
    font-size: 25px;
    overflow-x: unset;
}
.hiddenText{
    color: var(--bg);
    text-align: center;
    font-size: 11px;
}
.showRoomContainer .rewardSection button:hover + .hiddenText{
    display: block;
    color: var(--font2);
    text-align: center;
    font-size: 11px;
}
.showRoomContainer .rewardSection button:hover{
    cursor: pointer;
    box-shadow: var(--boxShadow);

}
.showRoomContainer .rewardAmount img{
    width: 20px;
    height: auto;
}
.showRoomContainer .noTokens{
    font-size: 40px;
    font-weight: 300;
    color: var(--accent2);
    text-align: center;
    grid-column: 1/-1;
    animation: changeBurnFontColor 2s ease infinite;
    padding-bottom: 10%;

}
@keyframes changeBurnFontColor{
    0%,100%{
        color: var(--accent2);
    }
    50%{
        color: var(--font);
    }
}

/* !=========================================================== */
/* ! unlisted owner section */
.showRoomContainer .mainTitle{
    font-size: 50px;
    text-align: center;
    color: var(--font);
    padding-top: 100px;
    padding-bottom: 50px;

}
.showRoomContainer .itemsGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(370px, 1fr));
    place-items: center;
    row-gap: 20px;
}
.showRoomContainer .item{
    border: 1px solid var(--font2);
    border-radius: var(--border);
    height: 500px;
    width: 350px;
    display: flex;
    flex-direction: column;
}
.showRoomContainer .title{
    font-size: larger;
    font-weight: 600;
    height: 40px;
    color: var(--font);
    padding: 0% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.showRoomContainer .reward{
    color: var(--font);
    font-size: 20px;
    text-align: center;
    overflow-x: unset;
}
.showRoomContainer .reward img{
    width: 15px;
    height: auto;
}
.showRoomContainer .interactionSection{
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: flex-end;
}
.showRoomContainer .details{
    height: 150px;
    display: flex;
    flex-direction: column;
}
.showRoomContainer .interactionSection .buttons{

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.showRoomContainer .interactionSection .sellBtn{
    width: 90%;
    background: var(--accent1);
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
    border-radius: var(--border);
    margin:0px auto;
    margin-top: 10px;
}
.showRoomContainer .interactionSection .sellBtn:hover{
    cursor: pointer;
    box-shadow:var(--boxShadow);
}
.showRoomContainer .interactionSection .claimBtn{
    width: 90%;
    background: none;
    color: var(--font);
    border: 1px solid var(--accent1);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: var(--border);
    margin:0px auto;
    margin-top: 10px;
}
.showRoomContainer .interactionSection .claimBtn:hover{
    cursor: pointer;
    box-shadow:var(--boxShadow);
}

/* !=========================================================== */
/* !listed owner section */

.showRoomContainer .listedItem{
    border: 1px solid var(--font2);
    border-radius: var(--border);
    height: 530px;
    width: 350px;
    display: flex;
    flex-direction: column;
}
.showRoomContainer .listedItem .details{
    height: 170px;
    display: flex;
    flex-direction: column;
    overflow-x: unset;
}
.showRoomContainer .listedItem .interactionSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 200px
}
.showRoomContainer .interactionSection .sellingPrice{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--font);
    margin-top: 10px;
    overflow-x: unset;
}
.showRoomContainer .listedItem .sellingPrice img{
    height: 15px;
    width: auto;
    pointer-events:none;
    
}
.showRoomContainer .interactionSection .unlistBtn{
    width: 90%;
    background: var(--accent1);
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
    border-radius: var(--border);
    margin:0px auto;
    margin-top: 10px;
    overflow-x: unset;

}
.showRoomContainer .interactionSection .unlistBtn:hover{
    cursor: pointer;
    box-shadow:var(--boxShadow);
}

/* !=========================================================== */
/*! popup sell menue */ 
.showRoomContainer #sellConfirmation{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0,0.9);
    justify-content: center;
    align-items: center;
    
}
#sellConfirmation .sellContainer{
    width: 400px;
    height: 210px;
    border: 1px solid var(--accent1);
    background: var(--bg);
    border-radius: var(--border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: unset;
}
#sellConfirmation .iconContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 50px;
    
}
.closeIcon {
    padding-right: 5px;
    padding-top: 5px;
    width: 25px;
    height: auto;
    color: var(--accent2);
}
.closeIcon:hover{
    cursor: pointer;
}
.sellContainer .selectedTitle{
    font-size: 30px;
    width: 360px;
    margin: 0 auto;
    color: var(--font);
    display: flex;
    justify-content: space-between;
}
.sellContainer .selectedReward{
    width: 500px;
    font-size: 25px;
    color: var(--font);
    text-align: center;
    
}
.sellContainer .selectedReward img{
    width: 20px;
    height: auto;
}
.sellContainer .interactionSelected{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    align-items: center;
    padding-bottom: 10px;
}
.sellContainer .interactionSelected .price{
    margin-top: 5px;
    width: 350px;
    padding-left: 10px;
    height: 30px;
    background: var(--btn);
    border-radius: var(--border);
    border: 2px solid var(--font);
    color: var(--font);
}
.sellContainer .interactionSelected input:focus-visible{
    outline: none;
}
.sellContainer .interactionSelected .sell{
    margin-top: 10px;
    width: 360px;
    height: 30px;
    background: var(--accent1);
    border-radius: var(--border);
    border: none;
    font-size: 20px;
    color: var(--bg);
    font-weight: 800;
}
.sellContainer .interactionSelected .sell:hover{
    box-shadow:var(--boxShadow);
    cursor: pointer;
}

/* //!=========================================================== */
/* pagination buttons */
.showRoomBase .paginationButtons{
    margin-top: unset;
}
/* //!=========================================================== */
/* mobile version */

@media all and (max-width: 1530px){
    .showRoomContainer .noTokens{
        font-size: 30px;
    }

}

@media all and (max-width: 1140px){
    .showRoomContainer .noTokens{
        font-size: 30px;
    }
   
}

@media all and (max-width: 800px){
    .showRoomContainer .noTokens{
        font-size: 20px;
    }
    .showRoomContainer .paginationButtons{
        width: 100%;
    }
    .showRoomContainer .itemsGrid {
        grid-template-columns:100%;
    }   

}

@media all and (max-width: 550px){
    .burnContainer .noTokens{
        font-size: 20px;
    }
    .showRoomContainer .mainTitle{
        padding-top: 65px;
    }

}
@media all and (max-width: 425px){
    .showRoomContainer .canvasContainer{
        width: 300px;
        height: 300px;
    }
    .showRoomContainer .item{
        width: 300px;
        height: 450px;
    }
}

@media all and (max-width: 320px){
    .showRoomContainer .canvasContainer{
        width: 200px;
        height: 200px;
        margin-bottom: 10px;
    }
    .showRoomContainer .item{
        width: 200px;
        height: 360px;
    }

    .showRoomContainer .paginationButtons{
        width: 70%;
    }
    .showRoomContainer .rewardSection button {
        width: 100%;
    }
    .showRoomContainer .noTokens{
        width: 50%;
    }
}