.museumContainer{
    min-width: 100vw;
    min-height: calc(100vh - 65px);
    background: var(--bg);
}

.museumContainer canvas{
    position: fixed;
    top: 65px;
    left: 0;
    outline: none;
}

.museumContainer .interactionSection{
    width: 99.5%;
    height: calc(100vh - 65px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}

.museumContainer .btn{
    position: relative;
    z-index: 1;
    background: none;
    color: var(--font);
    border-radius: var(--border);
    border: 1px solid var(--font);
    height: 40px;
    width:120px;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.museumContainer .btn:hover{
    box-shadow: var(--boxShadow);
}
.museumContainer .changeValue{
    position: relative;
    z-index: 1;
    background: none;
    color: #ffffff88;
    border-radius: var(--border);
    border: 1px solid var(--font);
    height: 40px;
    width:118px;
    text-align: center;
}
.museumContainer .edition{
    position: absolute;
    top: 70px;
    left: 10px;
    z-index: 1;
    color: var(--font);
    font-size: 40px;
    text-align: center;
}

.museumContainer .gen{
    position: absolute;
    top: 120px;
    left: 10px;
    z-index: 1;
    color: var(--font);
    font-size: 30px;
    text-align: center;
}