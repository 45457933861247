
.mainContainer{
    position: sticky;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: var(--bg);
    min-height: calc(100vh - 65px);
    padding-left:5%;
    padding-right:6%;
}
.marketCanvasContainer{
    width: 500px;
    height: 500px;
}
/* market infor section */
.marketInfo{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}
.marketInfo .title{
    font-size: 1.9vw;
    font-weight: 600;
    color: var(--font);
}

.marketInfo .value{
    display: flex;
    align-items: center;
    justify-content: center; 
    padding-top: 10px;
    color: var(--font);
   
}
.marketInfo .value .number{
    font-size: 1.4vw;
    padding: 10px;
}
.marketInfo img{
    height: auto;
    width: 1.5vw;
}

/* filter section */

.mainContainer .utils{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 50px auto;

}
.mainContainer .utils .paginationButtons{
    margin: unset;
}

.mainContainer .filter{
    display: flex;
}

.mainContainer .utils select{
    width: 150px;
    height: 35px;
    text-align: center;
    background: none;
    border: 1px solid var(--accent1);
    color: var(--font);
    margin-right: 5px;
    border-radius: var(--border);
    font-size: 18px;

}
.mainContainer .utils select:hover{
    cursor: pointer;
    box-shadow: var(--boxShadow);
}

.mainContainer .utils select option{
    color: var(--font2);
    background: none;
    border: 1px solid var(--accent1);

}
.mainContainer .utils .interaction .btn{
    width: 150px;
    height: 35px;
    text-align: center;
    background: none;
    border: 1px solid var(--accent1);
    color: var(--font);
    margin-left: 5px;
    border-radius: var(--border);
    font-size: 18px;
    cursor: pointer;
}
.mainContainer .utils .interaction .btn:hover{
    box-shadow: var(--boxShadow);
}


/* grid section */
.mainContainer .itemsGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(500px, 1fr));
    grid-gap:40px;
    justify-content: center;
    overflow-x: unset;
    place-items: center;
}

.mainContainer .item{
    position: relative;
    background: var(--bg);
    height: 650px;
    width: 500px;
    border-radius: var(--border);
    transition: 0.2s ease;
    overflow-x: unset;
    display: flex;
    flex-direction: column;
}

.mainContainer .item:hover {
    box-shadow: 0 3px 7px rgba(253, 253, 253, 0.3);
}

.mainContainer .rgb::after{
    content:"";
    background: linear-gradient(45deg,
    #000000 0%, 
    #616161 50%,
    #000000 100%  )
    repeat 0% 0% / 400% 100%;
    position: absolute;
    inset: -2.5px;
    border-radius: var(--border);
    filter: blur(1px);
    z-index: -1; 
    animation: rgb 1s linear infinite;
}


@keyframes rgb {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
} 


.mainContainer .item .details{
    width: 90%;
    height: 140px;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mainContainer .details .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    height: 40px;
    color: var(--font);
}

.mainContainer .details .buySection{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;


}
.mainContainer .buySection .price{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--font);
    font-size: 18px;
}
.mainContainer .buySection .price .p{
   color: var(--accent1);
   padding-right: 4px;
   animation: pColor 2s ease infinite;
}
@keyframes pColor{
    0%,100%{
        color: var(--accent1);
    }
    50%{
        color: var(--font);
    }
}
.mainContainer .buySection .price img{
    margin-left: 2px;
    height: 12px;
    width: auto;
}

.mainContainer .buySection .lastSoldPrice{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    font-weight: 600;
    color: var(--font);
    font-size: 18px;

}

.mainContainer .buySection .lastSoldPrice .l{
    padding-right: 4px;
    color: var(--accent2);
    animation: lColor 3s ease infinite;
}
@keyframes lColor{
    0%,100%{
        color: var(--accent2);
    }
    50%{
        color: var(--font);
    }
}

.mainContainer .buySection .lastSoldPrice img{
    margin-left: 2px;
    height: 12px;
    width: auto;
}
.mainContainer .buyButton button{
    cursor: pointer;
    border: 1px solid var(--accent1);
    background: none;
    width: 100%;
    text-align: center;
    border-radius: var(--border);
    font-weight: 600;
    height: 25px;
    font-size: 18px;
    color: var(--font);
    
}
.mainContainer  .buyButton button:hover{
    box-shadow: var(--boxShadow);

}

.selectedReward{
    font-size: 25px;
    color: var(--font);
    text-align: center;    
}
.selectedReward img{
    width: 20px;
    height: auto;
}
/* !======================================================= */
/* ! paginatoin section */
.paginationButtons{
    width: 500px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    border-top:1px solid var(--accent1);
    border-bottom:1px solid var(--accent1);
}

.paginationButtons li{
    padding: 5px;
    list-style: none;
    text-align: center;
}

.paginationButtons li a{
    cursor: pointer;
    color: var(--font);
    font-size: 20px;
}

.paginationButtons li:hover{
    cursor: pointer;
    box-shadow: var(--boxShadow);
}

.paginationActive{
    box-shadow: var(--boxShadow);
}


/* !============================================================= */
/* ! mobile version */



@media all and (max-width: 1100px){
    .mainContainer .utils{
        flex-direction: column;
        align-items: center;
    
    }
    .mainContainer .filter{
        margin-bottom: 20px;
    }
    .mainContainer .utils .interaction{
        margin-top: 20px;
    }
}
@media all and (max-width: 700px){

    .marketInfo .value .number{
        font-size: 25px;
    }
    .marketInfo{
        width: 80%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: space-evenly;
        align-items: flex-start;
        place-items: center;
    }

    .marketInfo img{
        height: auto;
        width: 20px;
    }
    .marketInfo .title{
        font-size: 25px;
    }
    .marketInfo .value{
        padding-top: unset;
    }

    .paginationButtons{
        width: 100%;

    }
}
@media all and (max-width: 500px){

    .mainContainer .marketCanvasContainer{
        width: 98vw;
        height: 98vw;
    }
    .mainContainer .item{
        width: 98vw;
        height: calc(100% + 10px);

    }
}

@media all and (max-width: 375px){
    .marketInfo .title{
        font-size: 5vw;
    }
    .mainContainer .item{
        height: calc(100% + 20px);
    }
    .marketInfo .value .number{
        font-size: 4vw;
        padding: 10px;
    }
    .marketInfo img{
        width: 3vw;
    }
    .mainContainer .utils .interaction .btn{
        width: 120px;
        font-size: 15px;
    }
}
@media all and (max-width: 320px){

    .mainContainer .itemsGrid{
        grid-template-columns: 100%;
        place-items: unset;
    }
}