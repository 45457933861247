.burnBase{
    width: 100%;
    height: 100%;
    background: var(--bg);
    padding-bottom: 100px;
}

.burnBase .maintitle{
    padding: 100px 0px;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: var(--font);
}

.burnContainer{
    width: 80%;
    margin: 0 auto;
}

.canvasContainer{
    width: 350px;
    height: 350px;
}


.burnContainer .itemsGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(370px, 1fr));
    place-items: center;
    row-gap: 20px;
}

.burnContainer .item{
    border: 1px solid var(--accent1);
    border-radius: var(--border);
    height: 460px;
    display: flex;
    flex-direction: column;
}

.burnContainer .title{
    font-size: larger;
    font-weight: 600;
    height: 40px;
    color: var(--font);
    padding: 0% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.burnContainer .burnSection{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.burnContainer .burnSection .price{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    color: var(--font);
}
.burnContainer .item .price img{
    height: 18px;
    width: auto;
    pointer-events:none;
    margin-left: 5px;
    
}


.burnContainer .burnSection .burnButton{
    width: 90%;
    background: var(--accent1);
    font-size: larger;
    font-weight: bolder;
    text-align: center;
    border-radius: var(--border);
}

.burnContainer .burnSection .burnButton:hover{
    cursor: pointer;
    box-shadow: 0 3px 7px rgba(253, 253, 253, 0.3);

}

.burnContainer .noBurn{
    font-size: 40px;
    font-weight: 300;
    color: var(--accent2);
    text-align: center;
    grid-column: 1/-1;
    animation: changeBurnFontColor 2s ease infinite;

}
@keyframes changeBurnFontColor{
    0%,100%{
        color: var(--accent2);
    }
    50%{
        color: var(--font);
    }
}

/* mobile version */

@media all and (max-width: 1400px){
    .burnContainer .noBurn{
        font-size: 30px;
    }
   
}

@media all and (max-width: 800px){
    .burnContainer .noBurn{
        font-size: 18px;
    }
    .burnContainer .paginationButtons{
        width: 100%;
    }
}

@media all and (max-width: 400px){
    .burnContainer .noBurn{
        font-size: 18px;
        margin-left: -20%;
    }
}

@media all and (max-width: 320px){
    .burnContainer .noBurn{
        margin-left: -30%;
    }
}
