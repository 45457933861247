
/* !============================================ */
/* mint container section */
.mintBase{
    background-color: var(--bg);
    width: 100%;
    min-height: 100%;
    
}
.mintContainer{
    opacity:1;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2,50%); 
    place-items: center;
    min-height: 100%;
    padding-bottom: 2vw;

}
.mintContainer .rightSide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}


/* !============================================ */
/* main shader section */
.shaderMainContainer{
    align-self: flex-end;
    z-index: 1;
    width: 45vw;
    height: 45vw;
}

#mainCanvas{
    position:relative;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
}


/* !============================================ */
/* mint info and title seciton */
.mintInfo{
    color: var(--font);
}
.mintInfo h3{
    font-size: 7vw;
    text-align: center;
}
.mintInfo p{
    font-size: 1.2vw;
    width: 500px;
    text-align: justify;
    color: var(--font2);
}
.mintInfo p .avax{
    color: var(--accent2);
}
.avax img{
    width: 22px;
    height: 22px;
    pointer-events:none
}

/* !============================================ */
/* timer section */
.mintContainer .timer{
    margin-top: 100px;
    text-align: center;
}
.remainingContainer{
    color: var(--font2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
}

.mintContainer .live{
    animation: changeColor 1s ease infinite;
}
@keyframes changeColor{
    0%,100%{
        color: var(--accent2);
    }
    50%{
        color: var(--font);
    }
}

/* !============================================ */
/* mint interaction section */
.mintInteraction{
    display: flex;
    flex-direction: column;
    align-items: center;


}
.mintInteraction input{
    width: 20vw;
    height: 50px;
    font-size: 25px;
    justify-self: center;
    border-radius: var(--border);
    text-align: center;
    background: var(--btn);
    border: 1px solid var(--accent1);
    display: block;
    margin-bottom: 10px;
    color: var(--font);
}
.mintInteraction button{
    display: block;
    width: 20vw;
    height: 50px;
    justify-self: center;
    border-radius: var(--border);
    color: var(--bg);
    background: var(--accent1);
    font-size: 25px;
    font-weight: bolder;
    border-style: none;
    margin-bottom: 10px;
    cursor: pointer;
}

.mintInteraction button:hover{
    box-shadow: var(--boxShadow);
}
.mintInteraction input:focus-visible{
    outline: none;
}
.mintInteraction .mintedAmount{
    color: var(--font2);
    font-size: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* free mint interaction */
.freeMintInteraction{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.freeMintInteraction button{
    display: block;
    width: 15vw;
    height: 30px;
    justify-self: center;
    border-radius: var(--border);
    color: var(--bg);
    background: var(--accent1);
    font-size: 20px;
    font-weight: bolder;
    border-style: none;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 50px;

}

.freeMintInteraction button:hover{
    box-shadow: var(--boxShadow);
}
.freeMintInteraction .freeMintedAmount{
    color: var(--font2);
    font-size: 20px;
}

/* !============================================ */
/* mobile version */
@media all and (max-width: 1400px){
    .mintContainer .timer{
        margin-top: 50px;
    }
}
  
@media all and (max-width: 1180px){

    .mintInfo p{
        width: 40vw;
    } 
    .avax img{
        width: 15px;
        height: 15px;
    }
    .mintContainer .timer{
        margin-top: 50px;
    }
    .mintContainer .timer .remainingTimer{
        font-size: 3vw;
    }
    .live{
        font-size: 1.3vw !important;
    }
    .mintInteraction input{
        width: 20vw;
        height: 3vw;
        font-size: 2vw;
    }
    .mintInteraction button{
        width: 20vw;
        height: 3vw;
        font-size: 2vw;
    }

}

@media all and (max-width: 800px){

    .mintContainer{
        width: 80%;
        grid-template-columns: 100%; 
    }

    .mintInfo{
        justify-self: center;
    }
    .mintInfo h3{
        font-size: 20vw;
        text-align: center;
    }

    .mintInfo p{
        font-size: 25px;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        width: 95vw;
    } 
    .avax img{
        width: 20px;
        height: 20px;
    }
    
    .mintContainer .timer .remainingTimer{
        font-size: 10vw;
    }

    .mintInteraction input{
        width: 60vw;
        height: 50px;
        font-size: 25px;
    }
    .mintInteraction button{
        width: 60vw;
        height: 50px;
        font-size: 25px;
    }
    .freeMintInteraction button{
        width: 50vw;
    }

    .shaderMainContainer{
        width: 99vw;
        height: 99vw;
    }
    .mintContainer{
        margin-top: unset;
    }
    .rightSide{
        margin-top: 20px;
    }
    .live{
        font-size: 3vw !important;
    }

}


@media all and (max-width: 400px){


    .mintInfo h3{
        font-size: 50px;
        text-align: center;
    }

    .mintInteraction input{
        width: 80vw;
    }
    .mintInteraction button{
        width: 80vw;
    }
    

}