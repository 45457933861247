:root {
  --bg: #000000;
  --accent1: #ffffff;
  --accent2: #FBA1A1;
  --font: #ffffff;
  --font2: #dbdada;
  --border:0.1em;
  --boxShadow:0 0px 15px rgb(253 253 253 / 70%);
}


/* global scroll bar */

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color:var(--bg);
}

body::-webkit-scrollbar {
  width: 3px;
  background-color: var(--bg);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--accent1);
}


.navBar{
  height: 65px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--bg);
}
.navBarWrapper{
  width: 70%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;

}
.nav-logo .logo{
  font-size: 40px;

}
.nav-logo .logo a{
  text-decoration: none;
  color: var(--accent1);
}
.nav-menues a{
  width: 140px;
  margin: 0px 25px;
  text-decoration: none;
  color: var(--font2);
  font-size: clamp(12px,1.5vw, 25px );
  text-align: center;
}

.nav-menues a:hover{
  background: none;
  color: var(--accent1);
  text-shadow: 0 3px 7px rgba(253, 253, 253, 0.9);
  cursor: pointer;
}
.nav-special button {
  border-style: none;
  background: var(--bg);
  font-size: 25px;
  color: var(--accent1);
  border: 1px solid var(--accent1);
  border-radius: var(--border);
  width: 250px;
  text-align: center;
}
.NavplaceHolder{
  display: none;
}
.nav-special button:hover{
  color: var(--font);
  box-shadow: var(--boxShadow);
  cursor: pointer;

}
.burger{
  display: none;
}


/* screen ration fix */

@media all and (max-width: 1600px){
  .nav-menues a{
    width: 100px;
    margin: 0px 10px;
  }
}

@media all and (max-width: 1180px){

  .nav-logo .logo{
    font-size: 20px;
  } 

  .nav-special button {
    font-size: 15px;
    width: 150px;
  
  }
}

@media all and (max-width: 800px){

  .navBarWrapper{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .nav-logo .logo{
    font-size: 40px;
  } 
  .nav-menues,.nav-special{
    display: none;
  }
  .nav-menues{
    height: calc(100vh - 65px);
  }
  .burger{
    display: block;
  }
  #hidden1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 65px;
    width: 100%;
    background: var(--bg);
    opacity: 0.95;
    z-index: 4;
  }
  #hidden1 a{
    font-size: 25px;
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  #hidden2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 90vh;
    width: 100%;    
    z-index: 4;
  }
  #hidden2 button{
    font-size: 25px;
    width: 250px;
  }

  .burgerIcon{
    display: inline-block;
    cursor: pointer;
  }
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 2px;
    background:var(--accent1);
    margin: 10px 0;
    transition: 0.4s;
  }
  #iconChange .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 8px) ;
  }

  #iconChange .bar2 {
    opacity: 0;
  }
  
  #iconChange .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
  }
}

@media all and (max-width: 400px){
  .navBarWrapper{
      width: 80% !important;
  }
}