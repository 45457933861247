
/* !===================================== */
/* About container section */
.aboutBase{
    background: var(--bg);
    height: 100%;
    width: 100%;

}
.aboutContainer{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2,50%);
}

/* !===================================== */
/* left section */
.aboutContainer .left{
    place-self: center;
}
.aboutContainer .title{
    font-size: 50px;
    font-weight: bolder;
    color: var(--font);
    margin-bottom: 20px;
}
.aboutContainer .description{
    
    font-size: 30px;
    color: var(--font2);
    text-align: justify;
    width: 600px;
}
/* !===================================== */
/* right section */
.aboutContainer .right{
    justify-self: end;
    width: 40vw;
    height: 40vw;
}

/* !===================================== */
/* mobile version */
@media all and (max-width: 1600px){
    .aboutContainer .right{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

    }
    .aboutContainer .left{
        margin-top: 100px;
    }
    .aboutContainer .description{
        font-size: 20px;
        width: 40vw;
    }
    .aboutContainer .title{
        font-size: 5vw;
    }

}
@media all and (max-width: 800px){
    .aboutContainer{
        width: 80%;
        grid-template-columns: 100%;
    }

    .aboutContainer .left{
        margin-top: 65px;
    }

    .aboutContainer .title{

        font-size: 50px;
        text-align: center;
    }

    .aboutContainer .right{
        margin-top: 20px;
        justify-self: center;
        width: 99vw;
        height: 99vw;
    }
    .aboutContainer .description{
        font-size: 30px;
        width: 85vw;
        text-align: center;
    }

}
@media all and (max-width: 400px){
    .aboutContainer .title{
        font-size: 40px;
    }
    .aboutContainer .description{
        font-size: 25px;

    }
}