.faqBase{
    width: 100%;
    height: 100%;
    background: var(--bg);
}
.faqBase .title{
    padding: 100px 0px;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: var(--font);
}

.faqContainer{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faqContainer .questionAnswer{
    width: 100%;
}
.faqContainer .question{
    border: 1px solid var(--font2);
    width: 100%;
    margin: 0 auto;
    height: 100%;
    border-radius: var(--border);
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
}

.faqContainer .text{
    color: var(--font);
    font-size: 25px;
    margin-left: 30px;
    
}
.faqContainer .answer{
    color: var(--font2);
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 20px;

}